import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	constructor() { }

	async signIn(username, password): Promise<any>  {
		return Auth.signIn(username, password).then(user=>{
			if(user.challengeName == "NEW_PASSWORD_REQUIRED"){
				return {status:"NEW_PASSWORD_REQUIRED",user:user};
			}else{
				return {status:"logged",user: user.attributte};
			}
		}).catch(error =>{
			console.log('error signing in', error);
	        return {status:"error"};
		});
	}

	async signInAndComplete(username, password): Promise<any>  {
		return Auth.signIn(username, password).then(async user=>{
			if(user.challengeName == "NEW_PASSWORD_REQUIRED"){
				return await Auth.completeNewPassword(user,password,{}).then(user2=>{
					return {status:"logged",user: user2.challengeParam.userAttributes};
				}).catch(error => {
					console.log('error signing in', error);
			    	return {status:"error"};
				});
			}else{
				return {status:"logged", user: user.attributes};
			}
		}).catch(error =>{
			console.log('error signing in', error);
	        return {status:"error"};
		});
	}

	async completeProfile(user,password): Promise<boolean>{
		return Auth.completeNewPassword(user,password,{}).then(user=>{
			return true;
		}).catch(error => {
			console.log('error signing in', error);
	    	return false;
		});
	}

	async signOut() : Promise<boolean>{
		return Auth.signOut({ global: true }).then(()=>{
			return true;
		}).catch(error =>{
			console.log('error getting session in', error);
	       	return false;
		});
	}

	async isLoggedIn() : Promise<boolean>{	
		return Auth.currentAuthenticatedUser().then(user => {
			if(user.signInUserSession){
				return true;
			}else{
				return false;
			}
		}).catch(error => {
			console.log('error getting session in', error);
	       	return false;
		});
			
	}

  async getUserInfo() : Promise<any>{	
		return Auth.currentAuthenticatedUser().then(user=>{
			if(user.signInUserSession){
				return user;
			}else{
				return false;
			}
		}).catch(error =>{
			console.log('error getting session in', error);
	       	return false;
		});
			
	}

	signUp(user:any) : Promise<any>{
		return Auth.signUp(user)
	}
}
