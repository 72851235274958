import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TicketsService, EventInfo, TicketType, OrderData, TicketOrder} from '../../services/tickets.service'
import { APIService, /*CreateTicketOrderInput, CreateTicketOrderMutation,CreateTicketInput, CreateTicketMutation*/ } from '../../services/API.service';

declare const mercadopago:any;

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {

  private eventId:number;
  private promoterHandle:string;
  private eventInfo:EventInfo;
  private ticketsSelected;
  private orderPrepared;
  private loading:boolean=false;
  private orderData:OrderData={
          tickets:[],
          sessionHash:undefined,
          buyer:{
            name:'',
            email:'',
            mobile:'',
            promoter:undefined
          }
        };
  private totalTickets:number=0;
  private totalPrice:number=0;
  private confirmEmail:string=''
  private preferenceInitPoint:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ticketsService : TicketsService,
    private apiService: APIService) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.eventId = Number(params['id']);
      this.promoterHandle = params['promoter'];
      this.orderData.buyer.promoter= this.ticketsService.getPromoterForHandle(this.promoterHandle);
      this.eventInfo=this.ticketsService.getEventInfo(this.eventId);
      
      if(!this.eventInfo){
        //send error message or redirect somewhere

      }else{
        let now = new Date();
        this.eventInfo.ticketTypes.forEach(ticketType=>{
          //if we can still sell this ticket type
          if(now.getTime() <= ticketType.ticketSellEndDate.getTime()){
            let ticketOrder = {
              ticketType: ticketType,
              howMany: 0
            }
            this.orderData.tickets.push(ticketOrder)
          }
          
        });
      }
    });
  }
  addTicketToOrder(order:TicketOrder){
    if(order.howMany < this.eventInfo.maxTicketsPerBooking){
      order.howMany++;
      this.totalTickets++;
      this.totalPrice+= order.ticketType.price ;
    }

  }
  removeTicketToOrder(order:TicketOrder){
    if(order.howMany>0){
      order.howMany--;
      this.totalTickets--;
      this.totalPrice-= order.ticketType.price ;
    }
  }

  selectTickets():void {
    if(this.totalTickets == 0){
      alert('Por favor, selecciona algunos boletos.');
      return;
    }
    this.ticketsSelected = true;

  } 
  buyTickets():void{
    
    if(!this.isBuyerValid()){
      return;
    }
    this.loading=true;
    this.ticketsService.prepareOrder(this.orderData)
    .then(async data=>{
      console.log(data);

      /*
      let inputTicketOrder:CreateTicketOrderInput={
        MPpreferenceId:data.body.preference.id,
        status:'',
        buyerName:'',
        buyerEmail:'',
        buyerMobile:'',
        promoterHandle:''
      }

      let ticketOrderResult:CreateTicketOrderMutation = await this.apiService.CreateTicketOrder(inputTicketOrder);
      
      this.orderData.tickets.forEach(async ticketOrder=>{
        let ticketType = ticketOrder.ticketType;
        let howMany = ticketOrder.howMany;
        for(let i = 0 ; i < howMany; i++){
           let inputTicket:CreateTicketInput = {
             ticketOrderID:ticketOrderResult.id,
             showDate: ticketType.showDate.toISOString(),
             ticketTitle: ticketType.name,
             ticketDescription: ticketType.description,
             ticketPrice: ticketType.price
           }
           let ticketResult = await this.apiService.CreateTicket(inputTicket);
        }
      })
      
      this.orderData.sessionHash=ticketOrderResult.id;

      */
      this.orderData.sessionHash='XfBaks0012581';
      this.loading=false;
      this.orderData.tickets = this.orderData.tickets.filter(
      ticketOrder =>  ticketOrder.howMany>0);
      this.preferenceInitPoint = data.body.preference.init_point;
      this.orderPrepared=true;

    })
    .catch(error=>{
      this.loading=false;
      alert(error);
      console.log(error);
    });
  }
  isBuyerValid():boolean{
    let valid = true;
    if(this.orderData.buyer.name.trim().length == 0){
      valid=false;
      alert('Por favor, escribe tu nombre.');
    } else if(this.orderData.buyer.email.trim().length == 0){
      valid=false;
      alert('Por favor, escribe tu email y confirmación.');
    } else if(this.orderData.buyer.email.trim() != this.confirmEmail.trim()){
      valid=false;
      alert('Por favor, verifica que los emails coincidan.');
    } else if(Number(this.orderData.buyer.mobile) == NaN) {
      valid=false;
      alert('Por favor, verifica que tu número celuar solo contenga números.');
    } else if(this.orderData.buyer.mobile.trim().length!=10){
      valid=false;
      alert('Por favor, verifica que tu número celuar tenga 10 dígitos.');
    }

    return valid;
  }
}
