import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../environments/environment';


export type RegistrationConfiguration = {
  pageTitle:string;
  title: string;
  greeting: string;
  success:string;
  generatePassword: boolean;
  defaultPassword?: string;
  bannerUrl: string ;
  nameAttributes?:[RegistrationAttribute];
  customAttributes?:[RegistrationAttribute];
};
export type RegistrationAttribute = {
  label:string;
  type: string;
  options?: [string];
  mandatory: boolean;
  attributeName: string;
};

@Injectable({
  providedIn: 'root'
})

export class RegistrationConfigService {

  constructor(private http: HttpClient) { }

  getConfiguration():RegistrationConfiguration{
    //Get domain
    let hostname = window.location.hostname;

    let configuration = {
      pageTitle:'Event Horizon by In Pulse',
      title:'Event Horizon',
      greeting:'Plataforma para streaming masivo. Hospeda eventos de decenas de miles de usuarios. Para ingresar al evento demo utiliza tu usuario y contraseña.',
      success:'Registro completo. Revise su correo electrónico para encontrar su acceso.',
      generatePassword: true,
      bannerUrl:''
    }
    return configuration
  }
  getConfigurationFromJson():Observable<RegistrationConfiguration>{
    let hostname = window.location.hostname;
    let fileURL = environment.apiBaseURL+hostname+'.registration.json';

    return this.http.get<RegistrationConfiguration>(fileURL);
  }
}
