import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  private API_NAME = awsconfig.aws_cloud_logic_custom[0].name;

  constructor(
    private http: HttpClient) { }

  prepareOrder(data:OrderData):Promise<any> { 
    const apiName = this.API_NAME;
    const path = '/tickets/prepare-order';
    const myInit = { // OPTIONAL
      body: data,
      headers: {}, // OPTIONAL
    };
    return API.post(apiName, path, myInit);
  }

  getEventInfo(id:number):EventInfo{
    let event:EventInfo;
    switch(id){
      case 1:
        event = {
          id:id,
          poster_img:'https://stream.inpulse.mx/eventhorizon/assets/econcert1/poster_19_junio.png',
          name:'E-Concert Swag Black',
          place:'Online by IP Club',
          dateDescription:'19 de Junio de 2021',
          description:`En esta ocasión IP Club recibe en su stage virtual a un conjunto de artistas que alterarán tus sentidos. Disfruta un poco de R&B/Soul, Indie Rock, Trap & Boom Bap y deja que la vibra fluya.   
     <br><br>
    Además de no perderte del lanzamiento del sencillo por Swag Black. Déjate llevar por esta escena local mexicana y vivamos una velada a este estilo.  
    <br><br>
    <b>Line Up </b>
    <br><br>
    Swag Black . Laan . Moonlight . Gerry Azzid . Naitze . Purpple
    <br><br>
    Conducción del evento por Alan Romo`,
          maxTicketsPerBooking:10,
          faq:[
            {
              question:'¿Cómo entro al evento?',
              answer:'Te enviaremos un email con tus accesos a nuestra plataforma de streaming en cuanto recibamos confirmación de tu pago, lo cual, si es usando tarjeta bancarías será inmediato. <br><br>Recuerda que tu acceso es válido para un solo dispositivo. Si abres tu acceso en una segunda pestaña o dispositivo, las demás sesiones se desconectarán automáticamente.'
            },
            {
              question:'¿Cómo contacto al organizador?',
              answer:'Si quieres contactar al organizador para promover tu grupo musical o número artístico en futuras fechas escríbenos a <a href="mailto:sgarcia@inpulse.mx" >sgarcia@inpulse.mx</a>. También podemos ayudarte a organizar tu propio evento privado o público.'
            },
            {
              question:'¿Dan factura?',
              answer:'¡Sí! Envíanos tus datos de facturación al correo <a href="mailto:facturas@inpulse.mx" >facturas@inpulse.mx</a> y la emitimos sin problema. ' 
            },
            {
              question:'¿Dónde puedo encontrar su aviso de privacidad?',
              answer:'Lo puedes consultar directamente en nuestro sitio: <a href="https://www.inpulse.mx/avisodeprivacidad" target="_blank" >Aviso de privacidad</a>  ' 
            }
            
          ],
          ticketTypes: [
            {
              name:'Acceso general - Early bird',
              id:1,
              showDate:new Date('2021-06-19T18:00:00'),
              ticketSellEndDate:new Date('2021-06-13T18:00:00'),
              price:125.00,
              description:'<b>Precio especial por comprar anticipadamente.</b> Concierto totalmente en vivo con toda la producción, como si estuvieras físicamente en el recinto.'
            },{
              name:'VIP Meet & Greet - Early bird',
              id:2,
              showDate:new Date('2021-06-19T18:00:00'),
              ticketSellEndDate:new Date('2021-06-13T18:00:00'),
              price:225.00,
              description:'<b>Precio especial por comprar anticipadamente.</b> Incluye además de la experiencia del concierto, una liga para el meet and greet en el soundcheck y un <b>cupón de $100MXN para Uber eats</b>'
            },{
              name:'Acceso general',
              id:3,
              showDate:new Date('2021-06-19T18:00:00'),
              ticketSellEndDate:new Date('2021-06-19T18:00:00'),
              price:150.00,
              description:'Precio especial por comprar anticipadamente. Concierto totalmente en vivo con toda la producción, como si estuvieras físicamente en el recinto.'
            },{
              name:'VIP Meet & Greet',
              id:4,
              showDate:new Date('2021-06-19T18:00:00'),
              ticketSellEndDate:new Date('2021-06-19T18:00:00'),
              price:250.00,
              description:'Precio especial por comprar anticipadamente. Incluye además de la experiencia del concierto, una liga para el meet and greet en el soundcheck y un <b>cupón de $100MXN para Uber eats</b>'
            }
          ]
        }
        break;

      case 2:
        event = {
          id:id,
          poster_img:'https://stream.inpulse.mx/eventhorizon/assets/potencia/poster.jpeg',
          name:'E-Concert Potencia con Rantan Fyah Cru + G-ill',
          place:'Online by IP Club',
          dateDescription:'03 de Julio de 2021',
          description:`En esta ocasión IP Club recibe en su stage virtual a un conjunto de artistas que alterarán tus sentidos. Disfruta del sonido del Rap urbano y conoce esta propuesta innovadora ya reconocida en los circulos del género.  
    <br><br>
    <b>Line Up </b>
    <br><br>
    Potencia junto con Rantan Fyah Cru + G-ill`,
          maxTicketsPerBooking:10,
          faq:[
            {
              question:'¿Cómo entro al evento?',
              answer:'Te enviaremos un email con tus accesos a nuestra plataforma de streaming en cuanto recibamos confirmación de tu pago, lo cual, si es usando tarjeta bancarías será inmediato. <br><br>Recuerda que tu acceso es válido para un solo dispositivo. Si abres tu acceso en una segunda pestaña o dispositivo, las demás sesiones se desconectarán automáticamente.'
            },
            {
              question:'¿Cómo contacto al organizador?',
              answer:'Si quieres contactar al organizador para promover tu grupo musical o número artístico en futuras fechas escríbenos a <a href="mailto:sgarcia@inpulse.mx" >sgarcia@inpulse.mx</a>. También podemos ayudarte a organizar tu propio evento privado o público.'
            },
            {
              question:'¿Dan factura?',
              answer:'¡Sí! Envíanos tus datos de facturación al correo <a href="mailto:facturas@inpulse.mx" >facturas@inpulse.mx</a> y la emitimos sin problema. ' 
            },
            {
              question:'¿Dónde puedo encontrar su aviso de privacidad?',
              answer:'Lo puedes consultar directamente en nuestro sitio: <a href="https://www.inpulse.mx/avisodeprivacidad" target="_blank" >Aviso de privacidad</a>  ' 
            }
            
          ],
          ticketTypes: [/*
            {
              name:'Acceso general - Early bird',
              id:5,
              showDate:new Date('2021-07-03T20:00:00'),
              ticketSellEndDate:new Date('2021-06-26T20:00:00'),
              price:125.00,
              description:'<b>Precio especial por comprar anticipadamente.</b> Concierto totalmente en vivo con toda la producción, como si estuvieras físicamente en el recinto.'
            },{
              name:'VIP Meet & Greet - Early bird',
              id:6,
              showDate:new Date('2021-07-03T20:00:00'),
              ticketSellEndDate:new Date('2021-06-26T20:00:00'),
              price:225.00,
              description:'<b>Precio especial por comprar anticipadamente.</b> Incluye además de la experiencia del concierto, una liga para el meet and greet en el soundcheck y un <b>cupón de $100MXN para Uber eats</b>'
            },{
              name:'Acceso general',
              id:7,
              showDate:new Date('2021-07-03T20:00:00'),
              ticketSellEndDate:new Date('2021-07-03T20:00:00'),
              price:150.00,
              description:'Concierto totalmente en vivo con toda la producción, como si estuvieras físicamente en el recinto.'
            },{
              name:'VIP Meet & Greet',
              id:8,
              showDate:new Date('2021-07-03T20:00:00'),
              ticketSellEndDate:new Date('2021-07-03T20:00:00'),
              price:250.00,
              description:'Incluye además de la experiencia del concierto, una liga para el meet and greet en el soundcheck y un <b>cupón de $100MXN para Uber eats</b>'
            }*/
          ]
        }
        break; 
    }
    return event;
  }

  getEventInfoFromJSON(id:number):Observable<EventInfo>{
    let fileURL = environment.apiBaseURL+id+'.event.json';
    return this.http.get<EventInfo>(fileURL);
  }

  getPromoterForHandle(handle:string):Promoter{
    //TODO: use router to get params
    let hostname = window.location.hostname;
    let promoter = {
      id:0,
      handle:handle
    }
    return promoter;
  }
  
}

export type EventInfo = {
  id: number;
  poster_img:string;
  name:string;
  place:string;
  dateDescription:string;
  description:string;
  ticketTypes: TicketType[];
  maxTicketsPerBooking:number;
  faq:FaqItem[];
}
export type FaqItem = {
  question:string;
  answer:string;
}
export type TicketType = {
  id:number;
  name:string;
  showDate:Date;
  ticketSellEndDate:Date;
  price:number;
  availableTickets?:number;
  description:string;
}
export type Promoter = {
  id:number;
  handle:string;
}
export type OrderData = {
  tickets: TicketOrder[]
  sessionHash:string|undefined;
  buyer:Buyer;
}
export type TicketOrder ={
  ticketType: TicketType;
  howMany:number;
}
export type Buyer = {
  name:string;
  email:string;
  mobile:string;
  promoter:Promoter|undefined;
}
