import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../environments/environment';



export type LoginConfiguration = {
  pageTitle:string;
  title: string;
  greeting: string;
  keepGeneratedPassword: boolean;
  bannerUrl: string ;
  ticketEventId?:number;
  singleSession?:boolean;
  hasLobby?:boolean;
  hasRegistration?:boolean;
  registrationInstructions?:string;
  defaultPassword?:string;
};

@Injectable({
  providedIn: 'root'
})

export class LoginConfigService {

  constructor(private http: HttpClient) { }

  getConfiguration():LoginConfiguration{
  	//Get domain
  	let hostname = window.location.hostname;

  	let configuration = {
      pageTitle:'Event Horizon by In Pulse',
  		title:'Event Horizon',
  		greeting:'Plataforma para streaming masivo. Hospeda eventos de decenas de miles de usuarios. Para ingresar al evento demo utiliza tu usuario y contraseña.',
  		keepGeneratedPassword: true,
  		bannerUrl:''
  	}

  	switch (hostname) {
  		case "caispmex.eventhorizon.inpulse.mx":
  			// code...
        configuration.pageTitle = 'Eventos CAISPMEX';
  			configuration.title = 'Eventos CAISPMEX';
  			configuration.greeting = 'Para ingresar al evento utiliza tu usuario y contraseña.';
  			configuration.bannerUrl = 'assets/img/caispmex/login-banner.png';
  			configuration.keepGeneratedPassword = true;
  			break;
  		case "vinte2021.eventhorizon.inpulse.mx":
  			// code...
  			break;
  		case "inai2021.eventhorizon-public.inpulse.mx":
  			// code...
  			break;
  		case "hope.eventhorizon.inpulse.mx":
  			// code...
  			break;
  		case "iiar2021.eventhorizon.inpulse.mx":
  			// code...
  			break;
  		default:
  			// code...
  			break;
  	}
  	return configuration;
  }
  getConfigurationFromJson():Observable<LoginConfiguration>{
  	let hostname = window.location.hostname;
  	let fileURL = environment.apiBaseURL+hostname+'.login.json';

  	return this.http.get<LoginConfiguration>(fileURL);
  }
}
