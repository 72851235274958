import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RegistrationConfigService, RegistrationConfiguration } from '../services/registration-config.service';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  private loading:boolean = false;
  private success:boolean = false;
  private config:RegistrationConfiguration;
  private registrationFG = new FormGroup({
    email:new FormControl('',Validators.required),
    password: new FormControl('')
  },{ updateOn: 'blur' });

  constructor(
    private registrationConfigService:RegistrationConfigService, 
    private authService:AuthService, 
    private router:Router,
    private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("loading...");
    
    this.registrationConfigService.getConfigurationFromJson().subscribe(
      data => {
        if (data) {
          this.config = data;
          this.titleService.setTitle(this.config.pageTitle);
          this.config.nameAttributes.forEach( attr => {
            if(attr.mandatory){
              this.registrationFG.addControl( attr.attributeName, new FormControl('',Validators.required))  
            }else{
              this.registrationFG.addControl( attr.attributeName, new FormControl())
            }
            
          })
          this.config.customAttributes.forEach( attr => {
            if(attr.mandatory){
              this.registrationFG.addControl( attr.attributeName, new FormControl('',Validators.required))  
            }else{
              this.registrationFG.addControl( attr.attributeName, new FormControl())
            }
          })
          console.log(JSON.stringify(this.config) );
        }else{
          console.log('using local config.');
          this.config = this.registrationConfigService.getConfiguration();
          this.titleService.setTitle(this.config.pageTitle);
          console.log(JSON.stringify(this.config) );
        }
      },
      error => {
        console.log(error);
        console.log('using local config.');
        this.config = this.registrationConfigService.getConfiguration();
        this.titleService.setTitle(this.config.pageTitle);
        console.log(JSON.stringify(this.config) );
      });
    
  }
  signOn(){
    //check if is valid
    if(!this.registrationFG.valid){
      alert("Por favor, complete todos los campos obligatorios.")
      return
    }

    this.loading=true
    console.log(this.registrationFG.value)
    //let newUser
    let website = window.location.hostname;
    let username = this.registrationFG.value.email.trim()

    //if generatePassword
      //use default password
    let password = ''
    if(this.config.generatePassword){
      if(this.config.defaultPassword){
        password = this.config.defaultPassword
      }else{
        let rand = (Math.random()*999999)+''
        password = String(parseInt( rand )).padStart(6, '0');
      }
    }else{
      password = this.registrationFG.get("password").value
    }
    
    let attributes = Object.assign({},this.registrationFG.value)
    delete attributes.password
    attributes.website = website
    if(this.config.nameAttributes){
      let name = ''
      this.config.nameAttributes.forEach(attr=>{
        let part = this.registrationFG.get(attr.attributeName).value
        console.log("name part",part)
        if(part){
          name = name +' '+ this.registrationFG.get(attr.attributeName).value
        }
      })
      name = name.trim()
      attributes.name = name
    }
    

    let newUser = {
            username:username,
            password:password,
            attributes: {
                ...attributes
            }
    }
    console.log(newUser)

    
    this.authService.signUp(newUser)
      .then(result => {
        console.log(result)
        this.success = true
        this.loading = false
      })
      .catch(error=>{
        console.log(error)
        this.loading = false
        if(error.code == "UsernameExistsException"){
          let errorMessage = 'El correo que ingresó ya fue registrado con anterioridad. Le sugerimos revisar su correo electrónico para encontrar su acceso, o si lo prefiere, escríbanos a soporte@inpulse.mx'
          alert(errorMessage)
        }else{
          alert('¡ERROR AL REGISTRAR USUARIO! Por favor, escríbanos a soporte@inpulse.mx para brindarle ayuda.')  
        }
        
      })
    //on success show success panel

  }

}
