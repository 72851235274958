import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { VisibilityConfigService } from '../services/visibility-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private visibilityConfigService: VisibilityConfigService,
		private authService: AuthService ){}
	canActivate(
	    next: ActivatedRouteSnapshot,
	    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		if(this.visibilityConfigService.isPublicEventFromURL()){
			return this.visibilityConfigService.getVisibilityFromJson().toPromise()
				.then(data=>{
					if(data){
						if(data.isPublic == true){
							return true; 
						}else{
							return this.router.parseUrl('/login');
						}
					}else{ //if no config json explicitely allows this event to be public, require login
						return this.router.parseUrl('/login');
					}

				}).catch(error=>{ //if no config json explicitely allows this event to be public, require login
					return this.router.parseUrl('/login');
				});

		}else{
			return this.authService.isLoggedIn().then(isLogged => { 
		  		if(isLogged){
		  			return true;
		  		}else{
		  			return this.router.parseUrl('/login');
		  		}
		  	 }).catch(() => {
		        return this.router.parseUrl('/login');
		     });
		}
	    
	}
  
}
