import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../environments/environment';

export type EventVisibility ={
	isPublic: boolean,
  hasLobby?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class VisibilityConfigService {

  constructor(
  	private http: HttpClient) { }

  isPublicEventFromURL():boolean {
  	let hostname = window.location.hostname;
  	return hostname.toString().includes('-public.');
  }

  getVisibilityFromJson():Observable<EventVisibility>{
  	let hostname = window.location.hostname;
  	let fileURL = environment.apiBaseURL+hostname+'.visibility.json';

  	return this.http.get<EventVisibility>(fileURL);
  }
}
