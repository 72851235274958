/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateAttendanceInput = {
  id?: string | null;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
};

export type ModelAttendanceConditionInput = {
  username?: ModelStringInput | null;
  organization?: ModelStringInput | null;
  timestamp?: ModelStringInput | null;
  website?: ModelStringInput | null;
  app_state?: ModelStringInput | null;
  user_attributes?: ModelStringInput | null;
  and?: Array<ModelAttendanceConditionInput | null> | null;
  or?: Array<ModelAttendanceConditionInput | null> | null;
  not?: ModelAttendanceConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type Attendance = {
  __typename: "Attendance";
  id?: string;
  username?: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateAttendanceInput = {
  id: string;
  username?: string | null;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
};

export type DeleteAttendanceInput = {
  id: string;
};

export type ModelAttendanceFilterInput = {
  id?: ModelIDInput | null;
  username?: ModelStringInput | null;
  organization?: ModelStringInput | null;
  timestamp?: ModelStringInput | null;
  website?: ModelStringInput | null;
  app_state?: ModelStringInput | null;
  user_attributes?: ModelStringInput | null;
  and?: Array<ModelAttendanceFilterInput | null> | null;
  or?: Array<ModelAttendanceFilterInput | null> | null;
  not?: ModelAttendanceFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelAttendanceConnection = {
  __typename: "ModelAttendanceConnection";
  items?: Array<Attendance | null>;
  nextToken?: string | null;
};

export type CreateAttendanceMutation = {
  __typename: "Attendance";
  id: string;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAttendanceMutation = {
  __typename: "Attendance";
  id: string;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAttendanceMutation = {
  __typename: "Attendance";
  id: string;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetAttendanceQuery = {
  __typename: "Attendance";
  id: string;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAttendancesQuery = {
  __typename: "ModelAttendanceConnection";
  items: Array<{
    __typename: "Attendance";
    id: string;
    username: string;
    organization?: string | null;
    timestamp?: string | null;
    website?: string | null;
    app_state?: string | null;
    user_attributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateAttendanceSubscription = {
  __typename: "Attendance";
  id: string;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAttendanceSubscription = {
  __typename: "Attendance";
  id: string;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAttendanceSubscription = {
  __typename: "Attendance";
  id: string;
  username: string;
  organization?: string | null;
  timestamp?: string | null;
  website?: string | null;
  app_state?: string | null;
  user_attributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateAttendance(
    input: CreateAttendanceInput,
    condition?: ModelAttendanceConditionInput
  ): Promise<CreateAttendanceMutation> {
    const statement = `mutation CreateAttendance($input: CreateAttendanceInput!, $condition: ModelAttendanceConditionInput) {
        createAttendance(input: $input, condition: $condition) {
          __typename
          id
          username
          organization
          timestamp
          website
          app_state
          user_attributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAttendanceMutation>response.data.createAttendance;
  }
  async UpdateAttendance(
    input: UpdateAttendanceInput,
    condition?: ModelAttendanceConditionInput
  ): Promise<UpdateAttendanceMutation> {
    const statement = `mutation UpdateAttendance($input: UpdateAttendanceInput!, $condition: ModelAttendanceConditionInput) {
        updateAttendance(input: $input, condition: $condition) {
          __typename
          id
          username
          organization
          timestamp
          website
          app_state
          user_attributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAttendanceMutation>response.data.updateAttendance;
  }
  async DeleteAttendance(
    input: DeleteAttendanceInput,
    condition?: ModelAttendanceConditionInput
  ): Promise<DeleteAttendanceMutation> {
    const statement = `mutation DeleteAttendance($input: DeleteAttendanceInput!, $condition: ModelAttendanceConditionInput) {
        deleteAttendance(input: $input, condition: $condition) {
          __typename
          id
          username
          organization
          timestamp
          website
          app_state
          user_attributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAttendanceMutation>response.data.deleteAttendance;
  }
  async GetAttendance(id: string): Promise<GetAttendanceQuery> {
    const statement = `query GetAttendance($id: ID!) {
        getAttendance(id: $id) {
          __typename
          id
          username
          organization
          timestamp
          website
          app_state
          user_attributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAttendanceQuery>response.data.getAttendance;
  }
  async ListAttendances(
    filter?: ModelAttendanceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAttendancesQuery> {
    const statement = `query ListAttendances($filter: ModelAttendanceFilterInput, $limit: Int, $nextToken: String) {
        listAttendances(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            organization
            timestamp
            website
            app_state
            user_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAttendancesQuery>response.data.listAttendances;
  }
  OnCreateAttendanceListener: Observable<
    SubscriptionResponse<OnCreateAttendanceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAttendance {
        onCreateAttendance {
          __typename
          id
          username
          organization
          timestamp
          website
          app_state
          user_attributes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateAttendanceSubscription>>;

  OnUpdateAttendanceListener: Observable<
    SubscriptionResponse<OnUpdateAttendanceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAttendance {
        onUpdateAttendance {
          __typename
          id
          username
          organization
          timestamp
          website
          app_state
          user_attributes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateAttendanceSubscription>>;

  OnDeleteAttendanceListener: Observable<
    SubscriptionResponse<OnDeleteAttendanceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAttendance {
        onDeleteAttendance {
          __typename
          id
          username
          organization
          timestamp
          website
          app_state
          user_attributes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteAttendanceSubscription>>;
}
