import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { VisibilityConfigService } from '../services/visibility-config.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor( 
		private router: Router,
		private visibilityConfigService: VisibilityConfigService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


  		if(this.visibilityConfigService.isPublicEventFromURL()){
			return this.visibilityConfigService.getVisibilityFromJson().toPromise()
				.then(data=>{
					if(data){
						if(data.isPublic == true){
							if(data.hasLobby == true){
								return this.router.parseUrl('/lobby');
							}else{
								return this.router.parseUrl('/dashboard');
							}
							
						}else{
							return true;
						}
					}else{ //if no config json explicitely allows this event to be public, require login
						return true;
					}

				}).catch(error=>{ //if no config json explicitely allows this event to be public, require login
					return true;
				});

		}else{
			return true;
		}



    
  }
  
}
