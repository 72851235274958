import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoginConfigService, LoginConfiguration } from '../services/login-config.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private username:any;
  private password:any;
  private newPassword:any;
  private confirmPassword:any;
  private incompleteUser:any;
  private loading:boolean = false;

  private config:LoginConfiguration;

  constructor(
    private loginConfigService:LoginConfigService, 
  	private authService:AuthService, 
  	private router:Router,
    private titleService: Title) { }


  ngOnInit(): void {
    this.titleService.setTitle("loading...");
    
    this.loginConfigService.getConfigurationFromJson().subscribe(
      data => {
        if (data) {
          this.config = data;
          this.titleService.setTitle(this.config.pageTitle);
          console.log(JSON.stringify(this.config) );
          this.isLogged();
        }else{
          console.log('using local config.');
          this.config = this.loginConfigService.getConfiguration();
          this.titleService.setTitle(this.config.pageTitle);
          console.log(JSON.stringify(this.config) );
        }
      },
      error => {
        console.log(error);
        console.log('using local config.');
        this.config = this.loginConfigService.getConfiguration();
        this.titleService.setTitle(this.config.pageTitle);
        console.log(JSON.stringify(this.config) );
      });
  }



  isLogged(){
    this.authService.isLoggedIn()
    .then(isLogged => { 
      if(isLogged){
        this.config.hasLobby ?
        this.router.navigateByUrl('/lobby') :
        this.router.navigateByUrl('/dashboard') 
      }
    }).catch(error => {
        console.log(error);
    });
  }

  async signIn(){
    if(this.config.defaultPassword){
      this.password=this.config.defaultPassword
    }
    this.loading=true;
    
    let loggedIn;
    if(this.config.keepGeneratedPassword){
      loggedIn = await this.authService.signInAndComplete(this.username,this.password);
    }else{
      loggedIn = await this.authService.signIn(this.username,this.password);
    }
    console.log("uruapan", loggedIn)
    if( loggedIn.status == "NEW_PASSWORD_REQUIRED" ){
      this.incompleteUser = loggedIn.user;
      this.loading=false;
    }else if(loggedIn.status == "logged"){
      
      if(loggedIn.user.website == window.location.hostname){
        if(this.config.singleSession){
          await this.authService.signOut(); //global logout
          await this.authService.signIn(this.username,this.password);
        }
        this.loading=false;
        this.config.hasLobby ?
        this.router.navigateByUrl('/lobby') :
        this.router.navigateByUrl('/dashboard') 
      }else{ //different tenant, logout here and redirect
        let newURL = window.location.protocol+'//'+loggedIn.user.website;
        if(window.location.port){
          newURL = newURL + ':'+window.location.port
        }
        this.authService.signOut()
        .then(signedOut =>{
          this.loading=false;
          window.location.href=newURL;
        })
        .catch(error=>{
          window.location.href=newURL;
        })
      }
      
    }else{
      this.loading=false;
      alert("Usuario o contraseña incorrecta");
    }


  }

  /*
  signIn(){
    this.loading=true;
    if(this.config.keepGeneratedPassword){
      this.authService.signInAndComplete(this.username,this.password)
        .then(loggedIn=>{
          if( loggedIn.status == "NEW_PASSWORD_REQUIRED" ){
            this.loading=false;
            this.incompleteUser = loggedIn.user
          }else if(loggedIn.status == "logged"){
            this.loading=false;
            if(loggedIn.user){
              if(loggedIn.user.website == window.location.hostname){
                this.router.navigateByUrl('');
              }else{
                let newURL = window.location.protocol+'//'+loggedIn.user.website;
                if(window.location.port){
                   newURL = newURL + ':'+window.location.port
                }
                this.authService.signOut().then(signedOut =>{
                  window.location.href=newURL;
                })
                .catch(error=>{
                  window.location.href=newURL;
                })
                
              }
            }else{
              this.router.navigateByUrl('');
            }
            
          }else{
            this.loading=false;
            alert("Usuario o contraseña incorrecta");
          }
        }).catch(error => {
          this.loading=false;
          console.log('error ',error);
          alert("Usuario o contraseña incorrecta");
        });
    }else{
      this.authService.signIn(this.username,this.password)
        .then(loggedIn=>{
          if( loggedIn.status == "NEW_PASSWORD_REQUIRED" ){
            this.loading=false;
            this.incompleteUser = loggedIn.user
          }else if(loggedIn.status == "logged"){
            this.loading=false;
            if(loggedIn.user){
              if(loggedIn.user.website == window.location.hostname){
                this.router.navigateByUrl('');
              }else{
                let newURL = window.location.protocol+'//'+loggedIn.user.website;
                if(window.location.port){
                   newURL = newURL + ':'+window.location.port
                }
                this.authService.signOut().then(signedOut =>{
                  window.location.href=newURL;
                })
                .catch(error=>{
                  window.location.href=newURL;
                })
                
              }
            }else{
              this.router.navigateByUrl('');
            }
          }else{
            this.loading=false;
            alert("Usuario o contraseña incorrecta");
          }
        }).catch(error => {
          this.loading=false;
          console.log('error ',error);
          alert("Usuario o contraseña incorrecta");
        });

    }
  	
  }
  */

  completeProfile(){
    if( !this.newPassword || !(this.newPassword == this.confirmPassword)){
      alert("Las contraseñas no coinciden.");
      return;
    }
    this.loading=true;
    this.authService.completeProfile(this.incompleteUser,this.newPassword)
    .then(success=>{
      if(success){
        this.loading=false;
        this.router.navigateByUrl('');
      }else{
        this.loading=false;
        alert("Error al actualizar información.");
      }
    })
    .catch(error=>{
      this.loading=false;
      console.log('error ',error);
      alert("Error al actualizar información.");
    });
  }

}
