import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service'; 
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test : Date = new Date();
  
  constructor( 
    private authService : AuthService,
    private router : Router ) { }

  ngOnInit() {
  }
  logOut(){
    this.authService.signOut()
    .then(result=>{
      if(result == true){
        this.router.navigateByUrl('login');
      }else{
        alert("No se pudo cerrar sesión 😕");
      }
    })
    .catch(error =>{
      console.log(error);
      alert("No se pudo cerrar sesión 😕");
    })
  }
}
